<script setup>
/**
 * SupportChat - A support chat component
 * @author Virgilio Vázquez J. <virgilio.vazquez@jbge.com.mx>
 * @version 1.0
 */

import { sendMessage, login } from '@/services/chat'
import { copyToClipboard } from '@/utils'

import { onMounted, ref, computed, watch } from 'vue'
import { flatten } from 'lodash'

const DISPLAY_TIMEOUT = 500

const showGroups = ref(false)
const userMessage = ref('')
const loading = ref(false)
const userInput = ref(null)
const loggedIn = ref(false)
const chatWidget = ref({ name: 'housto' })
const chatGroups = ref([])
const chatConversation = ref([])
const chatPanelBtn = ref(true)
const chatPanelShow = ref(false)
const tooltipText = ref()
const showTooltip = ref()
const userSelectedDocuments = ref([])
const userSelectedGroups = ref([])
const groupSelectedDocuments = ref({})
const isDev = process.env.NODE_ENV === 'development'

const stateChatPanel = () => {
  console.log('stateChatPanel')
}

const toggleChatPanel = () => {
  chatPanelShow.value = !chatPanelShow.value
  if (!loggedIn.value) {
    chatLogin()
  }
}

const newEntries = computed(() =>
  flatten(
    chatConversation.value.map(({ ai, human }) =>
      [{ text: human, user: true }, { text: ai, ai: true }]
    )
  )
)

const documentsFromGroups = (groups) =>
  flatten(
    groups.map(({ documents }) => documents.map(({ name }) => name))
  )

const allDocuments = computed(() =>
  documentsFromGroups(chatGroups.value)
)

function hideMenu() {
  chatPanelShow.value = false
}

function SendMessage() {
  const text = userMessage.value
  // chatConversation.value.push({ human: `${text}` })
  userMessage.value = ''
  loading.value = true
  sendMessage({ text, conversation: chatConversation.value, documents: userSelectedDocuments.value })
    .then(({ response, message, userInput, conversation, responseHtml }) => {
      console.log('Conversation', { conversation, response, responseHtml })
      chatConversation.value.push({
        ai: responseHtml || response,
        aiRaw: response,
        human: text
      })
    })
    .catch(err => {
      console.log("AVI Request Error", { err })
    })
    .finally(() => {
      loading.value = false
    })
}

function focusTextArea() {
  setTimeout(() => {
    console.log('Trying to focus text', { userInput })
    userInput.value.focus()
  }, 100)
}

function chatLogin() {
  return login()
    .then(({ widget, user, groups, ...data }) => {
      loggedIn.value = true
      console.log('Logged in', { widget, user, groups, data })
      chatWidget.value = widget
      chatGroups.value = groups
    })
}

function resetChat() {
  chatConversation.value = []
  hideMenu()
  setTimeout(() => { chatPanelShow.value = true }, 500)
}

onMounted(() => {
  chatLogin()
})

watch(chatPanelShow, async () => {
  focusTextArea()
})

watch(chatWidget, async (value) => {
  const { message } = value
  console.log(message)
  if (!chatPanelShow.value) {
    tooltipText.value = message
    showTooltip.value = true
    setTimeout(() => showTooltip.value = false, 6000)
  }
})

watch(chatGroups, async (value) => {
  userSelectedDocuments.value = allDocuments.value
})

watch(userSelectedDocuments, async (value) => {
  groupSelectedDocuments.value = Object.fromEntries(
    chatGroups.value.map(({ name, documents }) => [
      name,
      documents.every(({ name }) => userSelectedDocuments.value.includes(name))
    ])
  )
  userSelectedGroups.value = chatGroups.value.filter(({ name, documents }) => {
    return documents.every(({ name }) => userSelectedDocuments.value.includes(name))
  }).map(({ name }) => name)

})

watch(userSelectedGroups, async (value) => {
  const groups = chatGroups.value.filter(({ name }) => userSelectedGroups.value.includes(name))
  console.log("UPDATE", userSelectedGroups.value, groups)

  // const nw userSelectedDocuments.value = documentsFromGroups(
  // chatGroups.value.filter(({ name }) => userSelectedGroups.value.includes(name))
  //  )
})

const copyText = (text) => {
  copyToClipboard(text)
}

const toggleGroup = (groupName) => {
  const group = chatGroups.value.find(({ name }) => name === groupName)
  const documents = group.documents.map(({ name }) => name)

  console.log("Filtering docs", { group })

  userSelectedDocuments.value = allDocuments.value.filter(doc => !documents.includes(doc))
}
</script>

<template>
  <v-content class="layout-fab">
    <v-tooltip top v-model="showTooltip" color="accent darken-3" max-width="400px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="chatPanelBtn" small fab falt fixed :disabled="!loggedIn && !isDev" top="top" right="right"
          :color="loggedIn ? 'accent darken-1' : 'warning'" class="layout-fab__btn noprint" v-on="on" v-bind="attrs"
          @click="toggleChatPanel">
          <v-icon>
            {{ loggedIn ? 'mdi-robot' : 'mdi-robot-dead-outline' }}
          </v-icon>
        </v-btn>

      </template>
      <p class="text-button">
        {{ tooltipText }}
      </p>
    </v-tooltip>
    <v-navigation-drawer right dense disable-resize-watcher temporary fixed v-model="chatPanelShow">
      <v-app-bar :dense="true" color="accent darken-1" dark>
        <v-app-bar-nav-icon @click="hideMenu">
          <v-icon> mdi-robot-outline </v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title>
          <h3 class="text-h5 text-uppercase">
            {{ chatWidget.name }}
          </h3>
        </v-toolbar-title>
        <v-spacer />
        <v-btn absolute dark fab bottom right small color="info darken-2" @click="resetChat">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-app-bar>

      <v-subheader class="layout-fab__header">
        <v-btn @click="showGroups = !showGroups" :depressed="showGroups" small dense>
          <v-icon small left> mdi-settings </v-icon>
          <strong>{{ userSelectedDocuments.length }}</strong> documentos en
          <strong> {{ chatGroups.length }} </strong> grupos
        </v-btn>
      </v-subheader>
      <template>
        <v-row justify="center" v-show="showGroups">
          <v-expansion-panels accordion dense>
            <v-expansion-panel v-for="(group, i) in chatGroups" :key="i" dense>
              <v-expansion-panel-header dense>
                <v-checkbox v-model="userSelectedGroups" :label="group.name" :value="group.name" disabled />
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list dense>
                  <template v-for=" doc in group.documents">
                    <v-list-item cols="12" dense>
                      <v-checkbox dense :label="doc.name" v-model="userSelectedDocuments" :value="doc.name" />
                    </v-list-item>
                  </template>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </template>
      <v-divider />
      <v-card>
        <v-card-title>

        </v-card-title>
        <v-card-text>

          <v-list dense>
            <v-list-item>
              <template>
                <v-icon color="accent"> mdi-robot-happy </v-icon>
              </template>
              <p class="chat-text system-text accent--text text--darken-3 font-weight-bold">

                {{ chatWidget.message }}
              </p>
            </v-list-item>
            <template v-for="{ human, ai, aiRaw }, i in chatConversation">
              <v-card class="chat-card" :key="i" elevation="0">
                <v-card-title>
                  <v-spacer />
                  <v-sheet elevation="2" shaped color="blue-grey lighten-4" rounded
                    class="chat-text text-button user-text pa-2">
                    {{ human }}
                    <v-btn icon x-small @click="copyText(human)" class="float-right" color="blue-grey lighten-2">
                      <v-icon> mdi-content-copy </v-icon>
                    </v-btn>
                  </v-sheet>
                </v-card-title>
                <v-card-text>
                  <span class="text-body-1 font-weight-bold accent--text">

                    <v-icon color="accent"> mdi-robot </v-icon>

                    {{ chatWidget.name }}
                  </span>
                  <p class="chat-text system-text" v-html="ai" />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn icon x-small class="mt-n12" @click="copyText(aiRaw)">
                    <v-icon> mdi-content-copy </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
            <v-skeleton-loader v-if="loading" type="list-item-avatar-three-line" loading-text="cargando..."
              height="64px" tile />
          </v-list>
        </v-card-text>
        <v-card-actions style="margin-top: auto;">
          <v-textarea clearable :disabled="loading" v-model="userMessage" name=userInput
            placeholder="Escribe un mensaje" shaped @keyup.enter="SendMessage" outlined ref="userInput"
            :append-icon="!loading ? 'mdi-chat' : 'mdi-dots-horizontal'" />
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>
  </v-content>
</template>

<style scoped>
.layout-fab {
  position: absolute;
}

.layout-fab__btn {
  margin-top: 4px;
  /* fixme: vertical align */
  top: 90% !important;
  right: 0 !important;
  border-radius: 0 !important;
}

.layout-fab__switch {
  margin-top: 4px;
}

.layout-fab__header {
  padding: 0 12px 0 12px !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 12px 12px;
}

@media print {
  .noprint {
    display: none;
  }
}

.chat-text {
  text-wrap-mode: wrap;
  display: inline-block;
  /* Ensures the background doesn't span the full width */
  word-break: normal;
}

.user-text {
  text-transform: none !important;
}

.system-text {}

i.v-icon.chat-icon {
  margin-left: auto;
  margin-right: auto;
}

.chat-entry.v-list-item {
  margin-bottom: 2px;
  line-height: unset;
  height: unset;
  max-height: 1000px;
  min-height: 20px;
}

.progress-container {
  justify-content: center;
}

.menu-chat-content.v-menu-content {
  max-height: 90vh;
}

.chat-card.v-card .v-card__text {
  padding: 0;
}

.chat-card.v-card .v-card__actions {
  padding: 0;
  margin-top: -28;
}
</style>
